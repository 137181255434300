.About {
    background-color: #6aa84f;
    height: 100vh;
}

#generalAbout {
    margin-left: 25vw;
    margin-top: -50px;
    margin-bottom: 50px;
    width: 50vw;
    font-size: x-large;
    text-align: center;
}

.infoArea {
    width: 50vw;
    height: 50vh;
    margin-left: 32.5vw;
    display: grid;
    grid-template-areas: 
        "price ops"
        "optional tipping";
}

.infoHeader {
    background-color: lightgreen;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 8vw;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px green;
}

.infoBody {
    display: none;
    text-align: center;
    margin-left: -65px;
    width: 210px;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    background-color: rgb(165, 243, 165);
}

.infoHeader:hover + .infoBody {
    display: block;
}

#price {
    grid-area: "price";
    width: 12.5vw;
    height: 12.5vh;
}

#operations {
    grid-area: "ops";
    width: 12.5vw;
    height: 12.5vh;
}

#optional {
    grid-area: "optional";
    width: 12.5vw;
    height: 12.5vh;
}

#tipping {
    grid-area: "tipping";
    width: 12.5vw;
    height: 12.5vh;
}