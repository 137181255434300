.AddDelivery {
    background-color: #6aa84f;
    height: 100vh;
}

.RecipientSearch, .ServiceBar {
    height: 20vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dataResult {
    background-color: white;
    width: 300px;
    max-height: 100px;
    margin-left: 30px;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
}

.searchResult {
    border-radius: 5px;
    margin-top: 5px;
    padding-left: 10px;
    height: 25px;
}

.searchResult:hover {
    background-color: lightgray;
}

input {
    height: 30px;
    width: 300px;
    display: flex;
    justify-content: center;
    margin-left: 30px;
    font-size: medium;
}

.ServiceTitle {
    padding-right: 10px;
}

.ServiceInputs {
    padding-left: 6px;
}

.DeliveryNotes {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

#DeliveryNotesTitle {
    padding-right: 30px;
}

#DeliveryNote {
    resize: none;
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
    height: 20vh;
    width: 300px;
    border-radius: 5px;
    border-width: 3px;
    margin-left: 59px;
    border-left-style: groove;
    border-top-style: groove;
    border-style: inset;
    border-width: 2px;
}

#LogDelivery {
    padding-left: 45vw;
    padding-top: 50px;
}

#logDeliveryButton {
    height: 30px;
    width: 150px;
}