.Contact {
    background-color: #6aa84f;
    height: 100vh;
}

#generalContact {
    margin-top: -40px;
    display: flex;
    justify-content: center;
}

#serviceTicket {
    background-color: lightgreen;
    border-radius: 8px;
    width: 50vw;
    margin-left: 25vw;
    height: 50vh;
}

#ticketDesc {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

#ticketHeader {
    padding-left: 5px;
}

#ticketSubject, #ticketDescription {
    display: flex;
    align-items: center;
    justify-content: center;
}

#ticketDescriptionArea {
    resize: none;
    border-radius: 5px;
    border-left-style: groove;
    border-top-style: groove;
    font-family: Arial, Helvetica, sans-serif;
    font-size: medium;
    border-style: inset;
    border-width: 2px;
    height: 20vh;
    width: 31.5vw;
    margin-left: 5px;
}

#descriptionHeader {
    padding-bottom: 60px;
}

#ticketSubjectInput {
    width: 31.5vw;
}

#submitTicket {
    width: 10vw;
    height: 5vh;
    margin-left: 20vw;
    margin-top: 5vh;
}