.Landing {
    background-color: #6aa84f;
    height: 100vh;
}

.landingArea {
    margin-left: 20vw;
    width: 60vw;
    height: 40vh;
    display: grid;
    grid-template-areas: 
        "Login Signup"
        "About Contact";
}

.landingButtonArea {
    width: 30vw;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingButton {
    border: none;
    height: 10vh;
    width: 15vw;
    font-size: large;
    background-color: lightgreen;
}

.landingButton:hover {
    height: 11vh;
    width: 16vw;
    box-shadow: 5px 5px green;
    font-size: larger;
}

#landingLoginArea {
    grid-area: "Login";
}

#landingSignUpArea {
    grid-area: "Signup";
}

#landingAboutArea {
    grid-area: "About";
}

#landingContactArea {
    grid-area: "Contact";
}