.Dashboard {
    background-color: #6aa84f;
    height: 100vh;
}

#signOutButton {
    height: 35px;
    width: 70px;
    margin-left: 90vw;
}

#addDeliveryButton {
    height: 50px;
    width: 100px;
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 45vw;
}

#tableArea {
    display: grid;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 1vh;
    max-height: 50vh;
    overflow-y: scroll;
}

#tableSearchInput {
    margin-left: 10vw;
    width: 250px;
}

.statusButton {
    width: 100%;
    height: 100%;
}