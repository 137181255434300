.Login {
    background-color: #6aa84f;
    height: 100vh;
}

.loginBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    font-size: xx-large;
}

.banner {
    padding-left: 1%;
    padding-right: 1%;
}

.loginFlow {
    display: grid;
    grid-template-columns: 
        "username"
        "password"
        "button";
    height: 66vh;
}

.usernameInput {
    grid-column: username;
    height: 22vh;
    width: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.passwordInput {
    grid-column: password;
    height: 22vh;
    width: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    grid-column: button;
    height: 22vh;
    width: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    width: 50%;
    height: 20%;
    border-radius: 8px;
}

input {
    width: 50%;
    height: 10%;
    border-radius: 6px;
}

#loginInputSection {
    display: grid;
    grid-template-columns: 
        "text"
        "input";
}

#inputText {
    grid-column: text;
    width: 33vw;
    margin-left: 30px;
}

#usernameInput, #passwordInput {
    grid-column: input;
    height: 5vh;
    width: 50vw;
    font-size: medium;
    margin-bottom: 20%;
}