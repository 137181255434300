.Signup {
    background-color: #6aa84f;
    height: 100vh;
}

h1 {
    margin-top: 0px;
}

.signupBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.signupItems {
    display: flex;
    align-items: center;
    justify-content: center;
}

#suTitle {
    margin-top: 6.5vh;
}

#suInput {
   margin-top: 3vh;
}

.signupButton {
    height: 5vh;
    width: 10vw;
    margin-top: 5vh;
    margin-left: 45vw;
}