.Confirmation {
    background-color: #6aa84f;
    height: 100vh;
    overflow-y: hidden;
}

#confirmationNote {
    display: flex;
    justify-content: center;
    align-items: center;
}