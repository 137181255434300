.Account {
    background-color: #6aa84f;
    height: 100vh;
    overflow-y: hidden;
}

#accountTable {
    display: grid;
    margin-left: 10vw;
    margin-right: 10vw;
    max-height: 45vh;
    overflow-y: scroll;
}

#signOutButton {
    height: 35px;
    width: 70px;
    margin-left: 90vw;
    margin-top: -35px;
}

#serviceButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    width: 50vw;
    margin-top: 5vh;
    margin-left: 25vw;
}

#billButton, #supportButton {
    height: 7vh;
    width: 14vw;
}

#billButton {
    margin-right: 5vw;
}

.homeButton{
    font-size: xx-large;
    cursor: pointer;
    padding-top: 10px;
    padding-left: 10px;
}

.dashUsername {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
}